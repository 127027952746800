import { startRumTracing } from '@sm/otel-web';
import config from './config';

const {
  rum: { enabled, settings },
} = config;

if (enabled) {
  const { app, beaconUrl, environment, rumAuth, version, sampleRate, ...options } = settings;

  startRumTracing({
    enabled,
    appName: app,
    beaconUrl,
    env: environment,
    rumAuth,
    sampleRate,
    version: version ?? '',
    options,
  });
}
