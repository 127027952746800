/**
 * Indicates whether the provided value is like the word "true" in any casing
 *
 * @param {string | boolean | undefined} value
 * @returns {boolean}
 */
export function asBool(value: string | boolean | undefined): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  return value?.toLowerCase() === 'true';
}

/**
 * Config
 *
 * @returns {object} config object
 */
export default {
  amplitudeToken: process.env.AMPLITUDE_TOKEN ?? '',
  dataAnalytics: {
    loggingAPIPath: `${process.env.APP_LOGGING_API_PATH ?? ''}`,
    dataAnalyticsAPIPath: process.env.APP_DATA_ANALYTICS_API_PATH ?? '',
    dataAnalyticsLogsName: process.env.APP_DATA_ANALYTICS_LOGS_NAME ?? '',
    dataAnalyticsLogsPath: `${process.env.APP_DATA_ANALYTICS_LOG_PATH ?? '/tmp'}/${
      process.env.APP_DATA_ANALYTICS_LOGS_NAME ?? 'respweb-data-analytics.log'
    }`,
  },
  assetsHost: process.env.ASSETS_PUBLIC_HOST ?? '',
  assetsPath: process.env.ASSETS_PUBLIC_PATH ?? '',
  oneTrust: {
    tag: process.env.ONETRUST_SURVEYMONKEY_TAG ?? '',
    src: process.env.ONETRUST_SURVEYMONKEY_SRC ?? '',
  },
  brandedWeblinkSmParam: process.env.BRANDED_WEBLINK_SM_PARAM ?? '',
  gtmId: process.env.GTM_ID ?? 'unset',
  security: {
    csp: {
      enabled: asBool(process.env.SECURITY_CSP_ENABLED ?? false),
      defaultSrc: process.env.SECURITY_CSP_DEFAULT_SRC,
      upgradeInsecureRequests: asBool(process.env.SECURITY_CSP_UPGRADE_INSECURE_REQUESTS ?? false),
      scriptSrc: process.env.SECURITY_CSP_SCRIPT_SRC,
      styleSrc: process.env.SECURITY_CSP_STYLE_SRC,
      objectSrc: process.env.SECURITY_CSP_OBJECT_SRC,
      baseUri: process.env.SECURITY_CSP_BASE_URI,
    },
  },
  rum: {
    enabled: asBool(process.env.OTEL_RUM_ENABLED ?? false),
    cdn: {
      version: process.env.OTEL_RUM_CDN_VERSION ?? 'latest',
      integrity: process.env.OTEL_RUM_CDN_INTEGRITY ?? '',
    },
    settings: {
      // required
      app: 'respweb',
      beaconUrl: process.env.OTEL_RUM_ENDPOINT ?? '',
      rumAuth: process.env.OTEL_RUM_AUTH ?? '',
      environment: process.env.APP_ENVIRONMENT ?? 'local',
      version: process.env.APP_VERSION,
      sampleRate: Number(process.env.OTEL_RUM_SESSION_SAMPLE_RATE ?? '0.5'),
      // optional
      debug: asBool(process.env.OTEL_RUM_DEBUG ?? false),
      instrumentations: {
        interactions: asBool(process.env.OTEL_RUM_INSTRUMENTATIONS_INTERACTIONS ?? false),
      },
    },
  },
};
